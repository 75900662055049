import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import SuccessIcon from './Icons/SuccessIcon';
import ErrorIcon from './Icons/ErrorIcon';
import InfoIcon from './Icons/InfoIcon';
import WarningIcon from './Icons/WarningIcon';
import BuddytoastIcon from './Icons/BuddytoastIcon';

import { settoastDetails } from '../../Redux/Slices/toastSlice';

const iconMapping = {
  success: <SuccessIcon />,
  error: <ErrorIcon />,
  info: <InfoIcon />,
  warning: <WarningIcon />,
  buddymsg: <BuddytoastIcon />,
};

export const Toast = ({ toaststate, message, icon, uniqueKey }) => {
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(100);
  const [isClosing, setIsClosing] = useState(false); // New state for handling close

  const handleClose = () => {
    setIsClosing(true); // Trigger closing state
    setProgress(0);

    setTimeout(() => {
      dispatch(settoastDetails({
        uniqueId: '',
        toaststate: false,
        message: '',
        icon: '',
      }));
      setIsClosing(false); // Reset closing state after removal
    }, 300); // Add delay to match transition timing
  };

  useEffect(() => {
    if (toaststate) {
      setProgress(100); // Reset progress when toast is shown
  
      const interval = setInterval(() => {
        setProgress(prev => {
          if (prev <= 0) {
            clearInterval(interval);
            handleClose(); // Trigger close when progress reaches 0
            return 0;
          }
          return prev - (100 / 30); // Decrease progress over 3 seconds (30 intervals)
        });
      }, 180);
  
      return () => clearInterval(interval);
    }
  }, [toaststate, dispatch]);
  
  const baseClasses = `fixed bottom-4 left-4 rounded-lg shadow-2xl z-50 transition-transform duration-300 ease-in-out`;

  // Light and Dark mode specific background and text classes
  const typeClasses = {
    success: 'bg-white dark:bg-[#22232a9e] dark:text-white text-green-800',
    error: 'bg-white dark:bg-[#22232a9e] dark:text-white text-red-800',
    info: 'bg-white dark:bg-[#22232a9e] dark:text-white text-blue-800',
    warning: 'bg-white dark:bg-[#22232a9e] dark:text-white text-yellow-800',
    buddymsg: 'bg-white dark:bg-[#22232a9e] dark:text-white text-indigo-800',
  };
  
  // Updated icon color classes with darker colors for light mode
  const iconColorClasses = {
    success: 'text-[#25ad58] dark:text-green-300',  // Darker for light mode
    error: 'text-red-700 dark:text-[#ef4444]',        // Darker for light mode
    info: 'text-blue-700 dark:text-blue-300',       // Darker for light mode
    warning: 'text-yellow-700 dark:text-yellow-300',// Darker for light mode
    buddymsg: 'text-indigo-700 dark:text-indigo-300',// Darker for light mode
  };
  
  // Updated progress bar color classes with darker colors for light mode
  const progressClasses = {
    success: 'bg-[#25ad58] dark:bg-green-300',  // Darker for light mode
    error: 'bg-red-700 dark:bg-[#ef4444]',        // Darker for light mode
    info: 'bg-blue-700 dark:bg-blue-300',       // Darker for light mode
    warning: 'bg-yellow-700 dark:bg-yellow-300',// Darker for light mode
    buddymsg: 'bg-indigo-700 dark:bg-indigo-300',// Darker for light mode
  };
  
  const animationClasses = toaststate && !isClosing
    ? 'translate-x-0 opacity-100'  // Visible state
    : '-translate-x-full opacity-0'; // Closed state
  
  return (
    <div
      key={uniqueKey}
      className={`overflow-hidden ${baseClasses} ${animationClasses} ${typeClasses[icon]}
      backdrop-blur-lg backdrop-filter backdrop-saturate-150 border dark:border-gray-600 border-gray-200`}
    >
      <div className="min-w-10 flex justify-between items-center py-3 px-4">
        {/* Icon with dynamic color */}
        <div className={`mr-3 ${iconColorClasses[icon]}`}>
          {iconMapping[icon] || <InfoIcon />}
        </div>
  
        <div className="flex flex-col">
          {/* Title */}
          <span className="font-bold text-sm">{message.title || "Notification"}</span>
          {/* Description */}
          <span className="text-xs text-gray-500 dark:text-gray-300 mt-1">{message.description || "This is a description of the notification."}</span>
        </div>
  
        {/* Close button */}
        <div onClick={handleClose} className="ml-7 mt-[2px] font-bold cursor-pointer hover:bg-slate-50 rounded-sm hover:text-slate-400 p-1 transition ease-in-out">
          <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='currentColor' viewBox='0 0 16 16'>
            <path d='M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z'/>
          </svg>
        </div>
      </div>
      <div className="h-1 w-full bg-gray-200 rounded mt-1">
        <div
          className={`h-full ${progressClasses[icon]} rounded`}
          style={{ width: `${progress}%`, transition: 'width 0.1s linear' }}
        />
      </div>
    </div>
  );
  
  
};
