// App.js
import React, { useState, useEffect, memo } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import Tour from 'reactour';
import { setUser, updateProfilePicture } from './Redux/Slices/userSlice';
import { setBuddyList, setDarkMode } from './Redux/Slices/BuddySlice';
import { checkToken, getAllBuddies, getProfilePicture } from './Services/ApiEnpoints';
import AuthenticatedRoute from './Services/AuthenticatedRoute';
import { Toast } from './Components/Toast/Toast';
import FeedbackPopUp from './Components/Sidebar/Component/FeedbackPopUp';
import BuddyLoader from './Components/Loader/BuddyLoader';
import BuddyErrorPage from './Pages/Error/BuddyError';
import { clearUser } from './Redux/Slices/userSlice';
import { resetState as resetBuddyState } from './Redux/Slices/userSlice';
import { resetState as resetPdfState } from './Redux/Slices/userSlice';
import { resetState as resetUserState } from './Redux/Slices/userSlice';



const Navbar = lazy(() => import('./Components/Navbar/Navbar'));
const Sidebar = lazy(() => import('./Components/Sidebar/Sidebar'));
const Content = lazy(() => import('./Main/Content'));
const Signin = lazy(() => import('./Pages/Signin/Signin'));
const Signup = lazy(() => import('./Pages/Signup/Signup'));
const AudioInterview = lazy(() => import('./Pages/Buddy/AudioInterview'));
const VideoInterview = lazy(() => import('./Pages/Buddy/VideoInterview'));
const authRoutes = ['/signin', '/signup', '/error'];
const interviewRoutes = ['/buddy/video-interview', '/buddy/audio-interview'];

const App = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [selectedOption, setSelectedOption] = useState('AI Interview Buddy');
  const [feedback, setFeedback] = useState(false);
  const [isShowingMore, setIsShowingMore] = useState(false);
  const [activeOption, setActiveOption] = useState('AI Interview Buddy');
  const [isSubmenuVisible, setIsSubmenuVisible] = useState(false);
  const [activeSubmenuOption, setActiveSubmenuOption] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [loading, setLoading] = useState(true); // New loading state
  const {
    toastDetails: { uniqueId, toaststate, message, icon },
  } = useSelector((state) => state.toastSliceInfo);

  const dispatch = useDispatch();
  const location = useLocation();
  const userData = JSON.parse(localStorage.getItem('userData'));
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  // Load user data from localStorage and set the user in Redux state


  const loadData = async () => {
    const token = localStorage.getItem('accessToken');
  
    if (!token) {
      redirectToSignIn("No token found. Redirecting to login...");
      return;
    }
  
    try {
      const isValidToken = await checkToken(token);
  
      if (!isValidToken) {
        // If the token is invalid, call redirectToSignIn directly
        redirectToSignIn("Invalid token. Redirecting to login...");
        return;
      }
  
      if (userData) {
        console.log("Token is valid, proceeding to load data...");
        
        try {
          const profileResponse = await getProfilePicture(userData?.id);
          if (profileResponse?.url) {
            dispatch(updateProfilePicture(profileResponse.url));
          }
        } catch (error) {
          console.warn("No profile picture found or error fetching profile picture, proceeding without it.");
        }
  
        try {
          const buddyData = await getAllBuddies(userData?.id, userData?.email);
          dispatch(setBuddyList(buddyData));
        } catch (error) {
          console.error('Error fetching buddies:', error);
        }
      }
    } catch (error) {
      console.error("Token check failed. Redirecting to login...", error);
      redirectToSignIn("Token is missing or expired. Redirecting to login...");
    } finally {
      setLoading(false); // Set loading to false after data fetching is done
    }
  };
  

  const redirectToSignIn = (message) => {  
    console.log(message);
  
    // Clear local and session storage
    localStorage.clear();
    sessionStorage.clear();
  
    // Redirect to sign-in page
    if (!authRoutes.includes(window.location.pathname)) {
      console.log("Redirecting to sign-in...");
      navigate("/signin");
    }
    
    // Set loading state to false after handling redirect
    setLoading(false);
  };
  
  

  useEffect(() => {
    loadData(); // Call the async function
  }, [user]);

  useEffect(() => {
    const logoutMessage = localStorage.getItem('logoutMessage');
    if (logoutMessage === 'User is logged out') {
      // Clear relevant local and session storage
      localStorage.removeItem('logoutMessage');
      localStorage.removeItem('userData');
      localStorage.removeItem('accessToken');
      localStorage.clear();
      sessionStorage.clear();

      dispatch(resetBuddyState());
      dispatch(resetPdfState());
      dispatch(resetUserState());
      dispatch(clearUser());
      // Navigate to sign-in page
      navigate("/signin");
    }
  }, [navigate]);

  const isAuthRoute = authRoutes.includes(location.pathname);
  const isInterviewRoute = interviewRoutes.includes(location.pathname);

  useEffect(() => {
    // Check localStorage for theme preference
    const storedTheme = localStorage.getItem('theme');
    const isDarkMode = storedTheme === 'dark';

    // Dispatch the theme setting to update the Redux store and apply the correct theme
    dispatch(setDarkMode(isDarkMode));

    // Ensure the document's class reflects the theme correctly
    document.documentElement.classList.toggle('dark', isDarkMode);
  }, [dispatch]);

  const toggleShowMore = () => {
    setIsShowingMore((prevIsShowingMore) => !prevIsShowingMore);
  };

  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);

  const closeTour = () => {
    setIsTourOpen(false);
    setCurrentStep(null);
  };

  const openTour = () => {
    console.log("Tour opened, please check");
    setIsTourOpen(true);
  };

  const accentColor = '#A020F0';

  const tourConfig = [
    {
      selector: '[data-tut="reactour__iso"]',
      content: `By switching this icon you can change the theme.`,
    },
    {
      selector: '[data-tut="reactour__logo"]',
      content: `You can add Buddy from here.`,
    },
    {
      selector: '[data-tut="reactour__copy"]',
      content: `You can see assessment reports from here.`,
    },
    {
      selector: '[data-tut="reactour__scroll"]',
      content: 'You can see billing details from here.',
    },
  ];

  return (
    <div className="relative w-screen max-h-screen flex bg-[#F2F2F2] dark:bg-[rgb(15,23,42)] overflow-hidden">
      {/* Show loader if loading */}
      {loading ? (
        <BuddyLoader openLoader={loading} handleClose={() => { }} />
      ) : (
        <>
          {/* Authentication Routes */}
          <Suspense
            fallback={
              <div
                style={{
                  width: '100vw',
                  height: '100vh',
                  color: 'black',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: 'white',
                }}
              >
                {/* <BuddyLoader openLoader={true} handleClose={() => { }} /> */}
              </div>
            }
          >
            {isAuthRoute && (
              <div className="h-screen w-full flex flex-col mx-auto">
                <Routes>
                  <Route path="/signin" element={<Signin openTour={openTour} />} />
                  <Route path="/signup" element={<Signup openTour={openTour} />} />
                  <Route path="/error" element={<BuddyErrorPage />} />
                  {/* Redirect to /signin if no match */}
                  <Route path="*" element={<Navigate to="/signin" replace />} />
                </Routes>
              </div>
            )}

            {/* Protected Routes */}
            {!isAuthRoute && (
              <>
                {/* Interview Routes */}
                {isInterviewRoute ? (
                  <div className="h-screen w-screen flex flex-col mx-auto">
                    <Routes>
                      <Route element={<AuthenticatedRoute />}>
                        <Route path="/buddy/video-interview" element={<VideoInterview />} />
                        <Route path="/buddy/audio-interview" element={<AudioInterview />} />
                        {/* Redirect to /buddy/ai-interview-buddy if no match */}
                        <Route
                          path="*"
                          element={<Navigate to="/buddy/ai-interview-buddy" replace />}
                        />
                      </Route>
                    </Routes>
                  </div>
                ) : (
                  // Main Application Routes
                  <>
                    <div
                      className={`${sidebarOpen ? 'w-[21.15%]' : 'w-[5.1%] min-w-[60px]'
                        } h-full transition-all duration-200`}
                    >
                      <Sidebar
                        currentStep={currentStep}
                        sidebarOpen={sidebarOpen}
                        feedback={feedback}
                        setFeedback={setFeedback}
                        setSidebarOpen={setSidebarOpen}
                        setSelectedOption={setSelectedOption}
                        selectedOption={selectedOption}
                        setActiveOption={setActiveOption}
                        activeOption={activeOption}
                        setIsSubmenuVisible={setIsSubmenuVisible}
                        isSubmenuVisible={isSubmenuVisible}
                        activeSubmenuOption={activeSubmenuOption}
                        setActiveSubmenuOption={setActiveSubmenuOption}
                      />
                    </div>
                    <div className="dark:bg-[#121417] p-[10px] h-screen w-full flex flex-col mx-auto">
                      <div className="h-full rounded-[19px] shadow-[0px_6px_12px_-2px_rgba(50,50,93,0.25),_0px_3px_7px_-3px_rgba(0,0,0,0.3)]">
                        <div className="h-[12%] max-h-[12%] w-full">
                          <Navbar
                            currentStep={currentStep}
                            sidebarOpen={sidebarOpen}
                            selectedOption={selectedOption}
                            toggleShowMore={toggleShowMore}
                            isShowingMore={isShowingMore}
                          />
                        </div>
                        <div className="rounded-bl-[18px] rounded-br-[18px] dark:border dark:border-[#333333] dark:border-t-0 dark:bg-[#19191c] bg-[#ffffff] flex flex-col w-full h-[88%] overflow-scroll">
                          <Routes>
                            <Route element={<AuthenticatedRoute />}>
                              {/* Protected Routes */}
                              <Route
                                path="/"
                                element={
                                  <Navigate to="/buddy/ai-interview-buddy" replace />
                                }
                              />
                              <Route
                                path="/buddy/ai-interview-buddy"
                                element={
                                  <Content
                                    openTour={openTour}
                                    setActiveSubmenuOption={setActiveSubmenuOption}
                                    setIsSubmenuVisible={setIsSubmenuVisible}
                                    setActiveOption={setActiveOption}
                                    selectedOption="AI Interview Buddy"
                                    toggleShowMore={toggleShowMore}
                                    isShowingMore={isShowingMore}
                                  />
                                }
                              />
                              <Route
                                path="/buddy/coffee-call-buddy"
                                element={
                                  <Content
                                    openTour={openTour}
                                    setActiveSubmenuOption={setActiveSubmenuOption}
                                    setIsSubmenuVisible={setIsSubmenuVisible}
                                    setActiveOption={setActiveOption}
                                    selectedOption="Coffee Call Buddy"
                                  />
                                }
                              />
                              <Route
                                path="/buddy/ai-interview-buddy/:buddyId"
                                element={
                                  <Content
                                    openTour={openTour}
                                    setActiveSubmenuOption={setActiveSubmenuOption}
                                    setIsSubmenuVisible={setIsSubmenuVisible}
                                    setActiveOption={setActiveOption}
                                    selectedOption="AI Interview Buddy"
                                    toggleShowMore={toggleShowMore}
                                    isShowingMore={isShowingMore}
                                  />
                                }
                              />
                              <Route
                                path="/buddy/coffee-call-buddy/:buddyId"
                                element={
                                  <Content
                                    openTour={openTour}
                                    setActiveSubmenuOption={setActiveSubmenuOption}
                                    setIsSubmenuVisible={setIsSubmenuVisible}
                                    setActiveOption={setActiveOption}
                                    selectedOption="Coffee Call Buddy"
                                  />
                                }
                              />
                              <Route
                                path="/templates"
                                element={
                                  <Content openTour={openTour} selectedOption="Templates" />
                                }
                              />
                              <Route
                                path="/assessment-report"
                                element={
                                  <Content
                                    openTour={openTour}
                                    selectedOption="Assessment Report"
                                  />
                                }
                              />
                              <Route
                                path="/call-history"
                                element={
                                  <Content openTour={openTour} selectedOption="Call History" />
                                }
                              />
                              <Route
                                path="/billing"
                                element={<Content openTour={openTour} selectedOption="Billing" />}
                              />
                              <Route
                                path="/account"
                                element={<Content openTour={openTour} selectedOption="Account" />}
                              />
                              {/* Redirect to /buddy/ai-interview-buddy if no match */}
                              <Route
                                path="*"
                                element={
                                  <Navigate to="/buddy/ai-interview-buddy" replace />
                                }
                              />
                            </Route>
                          </Routes>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </Suspense>
          {feedback && (
            <FeedbackPopUp
              setFeedback={setFeedback}
              userEmail={userData?.email}
              userId={userData?.id}
              userFullName={userData?.fullName}
            />
          )}

          <Toast key={uniqueId} toaststate={toaststate} message={message} icon={icon} />

          <Tour
            onRequestClose={closeTour}
            steps={tourConfig}
            isOpen={isTourOpen}
            maskClassName="mask"
            className="helper"
            rounded={5}
            accentColor={accentColor}
            onAfterOpen={disableBody}
            onBeforeClose={enableBody}
            getCurrentStep={(step) => setCurrentStep(step)}
          />
        </>
      )}
    </div>
  );
};

export default memo(App);
