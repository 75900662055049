// import { configureStore } from '@reduxjs/toolkit';
// import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
// import buddyReducer from '../Slices/BuddySlice';

// const persistConfig = {
//   key: 'root',
//   storage,
// };

// const persistedReducer = persistReducer(persistConfig, buddyReducer);

// export const store = configureStore({
//   reducer: {
//     buddy: persistedReducer,
//   },
// });

// export const persistor = persistStore(store);

// Redux/Store/store.js
import { configureStore } from '@reduxjs/toolkit';
import buddyReducer from '../Slices/BuddySlice';
import toastReducer from '../Slices/toastSlice'
import userReducer from '../Slices/userSlice';
import pdfReducer from '../Slices/pdfSlice';

import { saveStateToLocalStorage, loadStateFromLocalStorage } from '../localStorageUtils';

// Load the initial state from local storage
const preloadedState = loadStateFromLocalStorage();

const store = configureStore({
  reducer: {
    buddy: buddyReducer,
    toastSliceInfo: toastReducer,
    user: userReducer,
    pdf: pdfReducer,
  },
  preloadedState, // Set the preloaded state if available
});

// Subscribe to store changes to save state to local storage
store.subscribe(() => {
  saveStateToLocalStorage(store.getState());
});

export default store;
